import React from 'react';
//import { courseApi } from './../store/coursestore'
import browser from './browserdetect';

class CheckBoxLabelTC extends React.Component  {

    constructor(props) {
        super(props);
        this.state = {
            isManagingFocus: false,
            rollOver:false,
            toggle:-1,
            tabindex:1,
        };
        this.myRef = React.createRef();
        this.chk = React.createRef();
    }

    componentDidMount() {
        if( this.props.fadeIn )
            this.fadeIn()

        if( this.props.isChecked ) {
            this.setState({toggle:(this.props.isChecked?1:-1)})
        }
    }
    componentWillUnmount() {
    }

    _onClick(ev) {
        ev.preventDefault();
        if(this.props.fadeOut )
            this.fadeOut()

        let toggle = this.state.toggle;

        if( toggle === -1) {
            toggle = 1;
            this.setState({toggle:1})
        } else if(toggle === 1) {
            toggle = -1;
            this.setState({toggle:-1})
        }

        if(this.props.onClick)
            this.props.onClick(toggle);
        if(this.props.onMstate)
            this.props.onMstate("click");
    }
    _onClickTCS(ev) {
        ev.preventDefault();
        
        if(this.props.onClickTCS)
            this.props.onClickTCS();
    }

    
    render() {
        let cls = this.props.className
        let ariaHidden = "false"
        if( this.state.tabindex === -1 )
            ariaHidden = "true"
        
        let innerCls = "cbox"
        if(this.state.toggle === 1 ) {
            innerCls = "cbox-check"
        }
        return (
            <div  
                ref={this.myRef} 
                className={cls} 
                title={this.props.title} 
                tabIndex={this.state.tabindex} 
                >
                <div className="innertext"
                    onClick={this._onClick.bind(this)} 
                >{this.props.caption}</div>
                &nbsp;<a href="" className="tcs"
                    onClick={this._onClickTCS.bind(this)} 
                >{this.props.tcs}</a>
                <div className={innerCls} ref={this.chk}
                    onClick={this._onClick.bind(this)} 
                ></div>
            </div>
            )
    }
}

export default CheckBoxLabelTC;