import React,{ useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";

import { gsap } from "gsap/all";
import { v4 as uuidv4 } from 'uuid';

import { useLoadApi } from '../store/preloaderstore'
import { appApi } from '../store/appstore'
import ManipulationSceneCameraLabel from "../components/ManipulationSceneCameraLabel"

import StdBtnPrevArrow from '../components/StdBtnPrevArrow'
import StdBtnGenericText from '../components/StdBtnGenericText'
import TotalStepsBottom from '../components/TotalStepsBottom'
import BackgroundRoundedUnder from '../components/BackgroundRoundedUnder'
import SpeechBubble from '../components/SpeechBubble'
import TopSpace from '../components/TopSpace'

const axios = require('axios').default;
const ls = require('local-storage');

//const birthday = "DD/MM/YYYY"; //new Date(1985, 11, 17);
const birthday = "DD/MM/YYYY"; //new Date(1985, 11, 17);

export default function EditBirthDatePage() {
  let navigate = useNavigate();

  const page = useRef(null);
  const mscene = useRef(null);
  const bgUnder = useRef(null);
  const bubble2 = useRef(null);
  const badWbubble = useRef(null);
  const badWbubble2 = useRef(null);
  const tpSpace = useRef(null);
  
  const btnWrap = useRef(null);
  const btnPrev = useRef();
  const btnNext = useRef();

  const refBD = useRef(null)
  const refTOL = useRef(null)

  const [translateName,setTranslateName] = useState(true)
  const [tcolor,setTColor] = useState("#b58500")
  const [bdvalue, setBDValue] = useState(birthday);
  const [showBDOverlay,setShowBDOverlay] = useState(1)

  useEffect(() => {
    setBGRemImage()
    bgUnder.current.update()
    let name = appApi.getState().playerName
    mscene.current.setNameText(name.toUpperCase() + "")
    mscene.current.setYear(appApi.getState().playerYear)
    if(appApi.getState().playerDate !== null) {
      console.log()
      setBDValue(appApi.getState().playerDate)

      if( appApi.getState().playerDate !== "MM/DD/YYYY" ) setShowBDOverlay(0)

    }
    tpSpace.current.update()
    adjustIframeHeight()
  },[]);

  const adjustIframeHeight = () => {
    const ph = gsap.getProperty(page.current,"height")
    console.log(ph)
    if ('parentIFrame' in window) {
      const ph = gsap.getProperty(page.current,"height")
      window.parentIFrame.size(parseInt(ph)); // Set height to 100px
    }
  }

  const setBGRemImage = () => {
    let imgd = useLoadApi.getState().imgData
    if(imgd) {
      mscene.current.createFromBase64( imgd )
    } else {
      let dest=useLoadApi.getState().getDestURL(),uuid=ls.get("uuid"),u
      if( (""+uuid).length > 10 ) u=dest+"/getremfile/rem-"+uuid+".png"
      else if(window.location.href.indexOf("lentfert")>-1) u = 'http://lentfertnuc/bmlabel/testimg'
      axios.get(u,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(res => {
        if(res.data) {
          if(res.data.hasOwnProperty("code") && res.data.code === 200 ) {
            mscene.current.createFromBase64( res.data.imgdata )
          }
        }
      }).catch((error) => {
        console.log(error);
        return error;
      })
    }
  }

  const onChangeBD = (e) => {
    let max_chars = 10;
    let bd = ""+e.target.value
    console.log(bd)
    let y = "0000"
    try {
      let s = bd.split("-")
      y = s[0];
    } catch(e) {
    }
    //-- hide birthday overlay
    setShowBDOverlay(0)

    gsap.set(refBD.current,{"color": "rgb(181, 133, 0)", "border":"2px solid black"})

    if( badWbubble.current.isVisible() ) badWbubble.current.hide()
    if( badWbubble2.current.isVisible() ) badWbubble2.current.hide()
    btnNext.current.setBlocked(false)

    //-- store the year
    mscene.current.setYear(y)
    appApi.getState().playerYear = y

    appApi.getState().playerDate = bd
    setBDValue(bd);
  }

  const onPrevious = (ev) => {
    navigate("/editname")
  }
  const onNext = (ev) => {

    btnNext.current.setBlocked(false)
    gsap.set(refBD.current,{"color": "rgb(181, 133, 0)", "border":"2px solid black"})

    let o18 = appApi.getState().is18()
    //-- check the birthdate
    if( o18.ok ) {
      appApi.getState().setShowBottleAnimOL(1)

      //-- now render the label bigger and get the image data
      if( 0 === 1 && mscene.current ) {
        let imgData = mscene.current.createLabelImageBig()
        console.log(imgData)
      }
      //-- show overlay and than go to name stuff etc. 
//      gsap.delayedCall(3.0,()=>{
      gsap.delayedCall(0.5,()=>{
          navigate("/showstories")
      })
      if(0 === 1 )
        gsap.delayedCall(6.7,()=>{
          appApi.getState().setShowBottleAnimOL(-1)
        })
    }
    else {
      if( o18.error === -1 ) {
//        console.log("not valid birthdate!")
        gsap.set(badWbubble2.current,{zIndex:200})
        badWbubble2.current.show()
        btnNext.current.setBlocked(true)

      } else {
        gsap.set(badWbubble.current,{zIndex:200})
        badWbubble.current.show()
        btnNext.current.setBlocked(true)

      }
      gsap.set(refBD.current,{"color": "rgb(255, 0, 0)",	"border":"2px solid red"})
    }
  }

  const onCloseClick = () => {
    btnNext.current.setBlocked(false)
  }

  const _onBlur = () => {
//    gsap.set(refBD.current,{type:"text"})
    refBD.current.value = bdvalue
  }
  const _onFocus = () => {
//    gsap.set(refTOL.current,{display:"none"})
    setShowBDOverlay(0)
//    gsap.set(refBD.current,{type:"date",pattern:"\d{2}/\d{2}/\d{4}"})
  }

  return (
    <div className="page" ref={page}>

      <SpeechBubble nr={2} className="speechbubbleBad dropshadow-bottom" ref={badWbubble} onCloseClick={onCloseClick} >
        <div className="text text-small">
        unfortunately,<br /> you have to 
be at <br />least 18 years old 
<br />to use this tool
        </div>
      </SpeechBubble>

      <SpeechBubble nr={2} className="speechbubbleBad dropshadow-bottom" ref={badWbubble2} onCloseClick={onCloseClick} >
        <div className="text">
          That is not<br />
          a valid <br />birthdate!
        </div>
      </SpeechBubble>

      <ManipulationSceneCameraLabel
        className="cwraplabel"
        showLabel={true} 
        yoffset={0} 
        renderBottle={0}
        canvwidth={window.screen.width}
        canvheight={Math.floor(window.screen.width*1.25)} 
        ref={mscene} 
      />
      
      <TopSpace className="" yOffset={-40} ref={tpSpace} />
      <div className="txtYourBirthDate">Your birthdate</div>
      <div className="name-input" lang="en">

        {showBDOverlay === 1 && <div ref={refTOL}
          className="birthday-ol" 
          style={{color:tcolor}}
        >{bdvalue}</div>}

        <input 
          ref={refBD} 
          type="date" 
          className="birthday" 
          pattern="\d{2}/\d{2}/\d{4}" 
          placeholder=" "
          min="1913-01-01" 
          max="2005-01-01" 
          onBlur={_onBlur} 
          onFocus={_onFocus} 
          onChange={onChangeBD} 
          value={bdvalue} 
          style={{color:tcolor}}
        />
      </div>
      <div className="skintone-mid">
        <div className="btnstwrapper" ref={btnWrap}>
          <StdBtnPrevArrow className="btnprev dropshadow-bottom" caption="" onClick={onPrevious} ref={btnPrev} />
          <StdBtnGenericText className="btnnext dropshadow-bottom" caption="NEXT" onClick={onNext} ref={btnNext} />
        </div>
      </div>
      <TotalStepsBottom currentStep={3} />
      <br />
      <br />

      <BackgroundRoundedUnder ref={bgUnder} />
      <div className="bg-img-bottle"><img src="gfx/bottle-dripts-bg.webp" className="inner" style={{marginTop:"-150px"}} /></div>

    </div>
  );
}
