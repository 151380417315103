import React from 'react';
//import { courseApi } from './../store/coursestore'
import { gsap, Power1 } from "gsap/dist/gsap";
import browser from './browserdetect';

class HowToWinPopup extends React.Component  {

    constructor(props) {
        super(props);
        this.state = {
            visible:false
        };
        this.myRef = React.createRef();
    }

    componentDidMount() {
    }
    componentWillUnmount() {
    }

    _onClick(ev) {
        ev.preventDefault();
        this.setState({visible:false})
        if(this.props.onClose)this.props.onClose()
    }

    show(pageHeight,dims) {
        this.setState({visible:true},()=>{
            gsap.set(this.myRef.current,{top:dims.y+window.scrollY-100})
        })
    }
    hide() {
        gsap.to(this.el.current,{duration:0.4,opacity:0,onComplete:()=>{
            this.setState({visible:false})
        }})
    }

    render() {
        let cls = this.props.className

        if( this.state.visible) {
            return (
                <div  
                    ref={this.myRef} 
                    className={cls} 
                    title={this.props.title} 
                    >
                    <div className="header">
                        <span>How to win <br />your very own<br />personalised gift</span>
                        <div className="close"
                            onClick={this._onClick.bind(this)}
                        ><img src="gfx/close-btn.png" /></div>
                    </div>
                    <div className="txt">
                        <ul>
                        <li>Set your profile to public</li>
                        <li>Tag <strong>@birramoretti</strong></li>
                        <li>Add hashtags <strong>#BirraMoretti</strong>&nbsp;<strong>#BirraMorettiboca</strong></li>
                        <li>Share it with Italian pride</li>
                        </ul>
<br />
And make sure you get the most likes you can! Then you have a shot at receiving your personalised bottle, apron, or glass. We announce 60 new winners every week!
                    </div>
                </div>
            )
        } else {
            return (<></>
            )
        }
    }
}

export default HowToWinPopup;