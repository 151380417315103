/*

  color mapping for better colors in face etc.
  https://jsfiddle.net/bigtimebuddy/57w8mnvz/
  
*/
import React,{ useState, useEffect, useLayoutEffect, Suspense, lazy } from "react";
import {
//  BrowserRouter as Router,
  HashRouter as Router,
  Route,
  Routes,
  useRoutes,
  useNavigate
} from "react-router-dom";

import * as PIXI from 'pixi.js-legacy'

import { gsap } from "gsap/all";
import { PixiPlugin } from "gsap/PixiPlugin";
//import { SplitText } from "gsap/SplitText";
import { appApi } from './store/appstore'
import { useLoadApi } from './store/preloaderstore'

import PreLoader from "./components/preloader";
import PreProcessing from "./components/PreProcessing"
//import WelcomeBack from "./components/WelcomeBack"

//import Routing from './RoutingTest'
import Routing from './Routing'
import "iframe-resizer/js/iframeResizer.contentWindow";
//import analytics from './analytics'
import './App.css';

//gsap.registerPlugin(SplitText);

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

appApi.getState().init()
 
PIXI.InteractionManager.prototype.onPointerDown = function (originalEvent) {
  // if we support touch events, then only use those for touch events, not pointer events
  if (this.supportsTouchEvents && originalEvent.pointerType === 'touch')
      { return; }
  var events = this.normalizeToPointerData(originalEvent);
  /*
   * No need to prevent default on natural pointer events, as there are no side effects
   * Normalized events, however, may have the double mousedown/touchstart issue on the native android browser,
   * so still need to be prevented.
   */
  // Guaranteed that there will be at least one event in events, and all events must have the same pointer type
  console.log("this.autoPreventDefault:",this.autoPreventDefault, window.__autoPreventDefault)

  if ( 1 === 1 && this.autoPreventDefault && events[0].isNormalized) {
    console.log("autopreventing 0 ")
      var cancelable = originalEvent.cancelable || !('cancelable' in originalEvent);
      if (cancelable) {
          originalEvent.preventDefault();
      }
  }
  if ( 1 === 1 && window.__autoPreventDefault && events[0].isNormalized) {
    console.log("autopreventing 1 ")
      var cancelable = originalEvent.cancelable || !('cancelable' in originalEvent);
      if (cancelable) {
          originalEvent.preventDefault();
      }
  }
  var eventLen = events.length;
  for (var i = 0; i < eventLen; i++) {
      var event = events[i];
      var interactionData = this.getInteractionDataForPointerId(event);
      var interactionEvent = this.configureInteractionEventForDOMEvent(this.eventData, event, interactionData);
      interactionEvent.data.originalEvent = originalEvent;
      this.processInteractive(interactionEvent, this.lastObjectRendered, this.processPointerDown, true);
      this.emit('pointerdown', interactionEvent);
      if (event.pointerType === 'touch') {
          this.emit('touchstart', interactionEvent);
      }
      // emit a mouse event for "pen" pointers, the way a browser would emit a fallback event
      else if (event.pointerType === 'mouse' || event.pointerType === 'pen') {
          var isRightButton = event.button === 2;
          this.emit(isRightButton ? 'rightdown' : 'mousedown', this.eventData);
      }
  }
};

PIXI.InteractionManager.prototype.onPointerMove = function (originalEvent) {
    // if we support touch events, then only use those for touch events, not pointer events
//    console.log("onPointerMove - 1")
    if (this.supportsTouchEvents && originalEvent.pointerType === 'touch')
        { return; }

//    console.log("onPointerMove - 2")
    var events = this.normalizeToPointerData(originalEvent);
    if (events[0].pointerType === 'mouse' || events[0].pointerType === 'pen') {
        this._didMove = true;
        this.cursor = null;
    }

    if ( 1 === 1 && window.__autoPreventDefault && events[0].isNormalized) {
      console.log("onPointerMove - autopreventing 1 ")
        var cancelable = originalEvent.cancelable || !('cancelable' in originalEvent);
        if (cancelable) {
            originalEvent.preventDefault();
        }
    }
  
    var eventLen = events.length;
    for (var i = 0; i < eventLen; i++) {
        var event = events[i];
        var interactionData = this.getInteractionDataForPointerId(event);
        var interactionEvent = this.configureInteractionEventForDOMEvent(this.eventData, event, interactionData);
        interactionEvent.data.originalEvent = originalEvent;
        this.processInteractive(interactionEvent, this.lastObjectRendered, this.processPointerMove, true);
        this.emit('pointermove', interactionEvent);
        if (event.pointerType === 'touch')
            { this.emit('touchmove', interactionEvent); }
        if (event.pointerType === 'mouse' || event.pointerType === 'pen')
            { this.emit('mousemove', interactionEvent); }
    }
    if (events[0].pointerType === 'mouse') {
        this.setCursorMode(this.cursor);
        // TODO BUG for parents interactive object (border order issue)
    }
};


if ('parentIFrame' in window) window.parentIFrame.size( 690 );
if ('parentIFrame' in window) window.parentIFrame.autoResize(false);

//scormApi.getState().init();

function App() {
  const canContinue = useLoadApi(state => state.canContinue)
  const [allLoaded, setAllLoaded] = useState(false);
  const [messageData, setMessageData] = useState()

  useEffect(() => {
    console.log("app allLoaded:",canContinue)
    
    if( canContinue ) {
      setAllLoaded(true)
    } else {
    }

    if ('parentIFrame' in window) window.parentIFrame.size( 690 );
    if ('parentIFrame' in window) window.parentIFrame.autoResize(false);
    
    return ()=>{
    }
  },[canContinue]);
  

  const onResized = data => setMessageData(data)

  const onMessage = data => {
    console.log(data)
//    setMessageData(data)
//    iframeRef.current.sendMessage('Hello back from the parent page')
  }  
  return (
    <>
      <Router>
        <PreLoader />
        <PreProcessing />
        
        {allLoaded && <Routing />}
      </Router>


    </>
  );
}

export default App;
