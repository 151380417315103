import React from 'react';
//import { courseApi } from './../store/coursestore'
import browser from './../components/browserdetect';
import { gsap } from "gsap/all";

class StdBtnGenericText extends React.Component  {

    constructor(props) {
        super(props);
        this.state = {
            isManagingFocus: false,
            rollOver:false,
            tabindex:1,
            blocked:false,
        };
        this.myRef = React.createRef();
    }

    componentDidMount() {
        if( this.props.fadeIn )
            this.fadeIn()

        if(this.props.startBlinking === 1 ) {
            this.startBlinkingAnim()
        }

        if( this.props.opacity !== undefined ) {
            console.log("StdBtnGenericText -> opacity:",this.props.opacity)
            this.setOpacity(this.props.opacity)
        }
    }
    componentWillUnmount() {
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.opacity !== this.props.opacity) {
            this.setOpacity(this.props.opacity)
        }
    }

    setOpacity(o) {
        gsap.set(this.myRef.current,{opacity:o})
    }

    showIn1(del) {
        gsap.fromTo(this.myRef.current,0.5,{scale:0,alpha:0},{delay:del,scale:1,alpha:1,ease:"back.out"})
    }
    
    _onBlur() {
        this._timeoutID = setTimeout(() => {
            if (this.state.isManagingFocus) {
                this.setState({
                    isManagingFocus: false,
                    rollOver: false,
                });
            }
        }, 0);
    }

    _onFocus() {
        clearTimeout(this._timeoutID);
        if (!this.state.isManagingFocus) {
            this.setState({
                isManagingFocus: true,
                rollOver: true,
            });
        }
    }

    _keypressHandler(ev) {
        console.log(ev.keyCode,ev.key)
        if( ev.key == " ") {
            this.myRef.current.click();
        }
    }

    _onClick(ev) {
        ev.preventDefault();
        if(this.state.blocked) return;

        if(this.props.fadeOut )
            this.fadeOut()

//        courseApi.getState().playSFX("sfxClick1")

        if(this.props.onClick)
            this.props.onClick(ev);
        if(this.props.onMstate)
            this.props.onMstate("click");
    }

    _onMouseOver(ev) {

//        courseApi.getState().playSFX("sfxRollover")

        //-- show over image!
            this.setState({
                rollOver: true,
            });
        if(this.props.onMouseOver)
            this.props.onMouseOver();
        if(this.props.onMstate)
            this.props.onMstate("over");
    }
    _onMouseOut(ev) {
        //-- show normal image!
            this.setState({
                rollOver: false,
            });
        if(this.props.onMouseOut)
            this.props.onMouseOut();
        if(this.props.onMstate)
            this.props.onMstate("out");
    }

    setBlocked(b) {
        if(b)gsap.set(this.myRef.current,{opacity:0.5})
        else gsap.set(this.myRef.current,{opacity:1})
        this.setState({blocked:b})
    }

    render() {
        let cls = this.props.className, clsExtra = this.props.classNameExtra
        if( this.state.rollOver ) {
            cls += "-over"
        }
        if(clsExtra)cls += " "+clsExtra

        let ariaHidden = "false"
        if( this.state.tabindex === -1 )
            ariaHidden = "true"
        
        return (
            <a href="#" 
                ref={this.myRef} 
                className={cls} 
                title={this.props.title} 
                onBlur={this._onBlur.bind(this)} 
                onFocus={this._onFocus.bind(this)} 
                onKeyPress={event => this._keypressHandler(event)} 
                aria-label={this.props.ariaLabel} 
                aria-hidden={ariaHidden} 
                role={this.props.role} 
                tabIndex={this.state.tabindex} 
                onClick={this._onClick.bind(this)} 
                onMouseOver={this._onMouseOver.bind(this)} 
                onMouseOut={this._onMouseOut.bind(this)} 
                >
                {this.props.caption}
            </a>
            )
    }
}

export default StdBtnGenericText;